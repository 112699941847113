import { Button } from "react-bootstrap"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"

const SortProjectionChart = ( { sort, direction } ) => {
  return (
    <div className='float-end mb-3'>
      <Button className='d-flex align-items-center' onClick={ () => sort( !direction ) } >
        { direction ? <FaArrowDown /> : <FaArrowUp /> } &nbsp;
        Product </Button>
    </div>
  )
}

export default SortProjectionChart