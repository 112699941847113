import './app.scss'

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Protected from './Protected'
import { SidebarMenu } from './components/SidebarMenu'
import { NavBar } from './components/Navbar'
import { useEffect, useState } from 'react'
import server from './server'
import { ToastContainer } from 'react-toastify'

/** Pages */
import Login from "./pages/auth/login"
import Customers from './pages/customers'
import CustomerForm from './pages/customers/forms'
import Products from './pages/products'
import Customer from './pages/customers/customer'
import SalesHistory from './pages/sales-history'
import Periods from './pages/periods'
import SalesHistoryEdit from './pages/sales-history/SalesHistoryEdit'
import CustomerProductEdit from './pages/customers/CustomerProductEdit'
import checkIfAdmin from './services/checkIfAdmin'

function App () {
  const [ isLoggedIn, setIsLoggedIn ] = useState( false )
  const [ user, setUser ] = useState( false )

  const checkAuth = () => {
    server.useApi( { url: '/api/v1/account/user/', method: 'get' } )
      .then( ( data ) => {
        setUser( data.data )
        setIsLoggedIn( true )
      } ).catch( ( err ) => {
        setIsLoggedIn( false )
      } )
  }

  useEffect( () => {
    checkAuth()
  }, [ isLoggedIn ] )

  const loggedIn = ( status ) => {
    setIsLoggedIn( status )
  }

  return (
    <>
      <ToastContainer />
      <Container fluid="full">
        <Router>
          { user && isLoggedIn ? <NavBar /> : false }
          { user && isLoggedIn ? <SidebarMenu /> : false }
          <Row>
            <Col>
              <main className={ user && isLoggedIn ? 'col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2' : '' }>
                <Routes>
                  <Route
                    path="/customers"
                    element={ <Protected>
                      <Customers /> </Protected> }
                  />
                  {
                    checkIfAdmin( user ) && <Route
                      path="/customer/:id/update"
                      element={ <Protected>
                        <CustomerForm /> </Protected> }
                    />
                  }
                  <Route
                    path="/customer/add"
                    element={ <Protected>
                      <CustomerForm /> </Protected> }
                  />
                  <Route
                    path="/customers/:id"
                    element={ <Protected>
                      <Customer /> </Protected> }
                  />
                  <Route
                    path="/products"
                    element={ <Protected>
                      <Products /> </Protected> }
                  />
                  {
                    checkIfAdmin( user ) && <Route
                      path="/customer/sales-history/:id/edit"
                      element={ <Protected>
                        <SalesHistoryEdit /> </Protected> }
                    />
                  }
                  {
                    checkIfAdmin( user ) && <Route
                      path="/customer/:customer/product/:id/edit"
                      element={ <Protected>
                        <CustomerProductEdit /> </Protected> }
                    />
                  }
                  <Route
                    path="/sales-history"
                    element={ <Protected>
                      <SalesHistory /> </Protected> }
                  />
                  <Route
                    path="/periods"
                    element={ <Protected>
                      <Periods /> </Protected> }
                  />
                  <Route
                    path="/login"
                    element={ <Login loggedIn={ loggedIn } /> }
                  />
                  <Route path="*" element={ <Login loggedIn={ loggedIn } /> } />
                </Routes>
              </main>
            </Col>
          </Row>
        </Router>
      </Container >
    </>
  )
}

export default App
