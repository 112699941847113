import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import server from '../../server'
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Select from 'react-select'
import { CUSTOMER_STATUS, ROUTES, TERRITORIES, ZONES } from '../../services/constant'
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom"

import "./index.scss"

const CustomerForm = () => {
    const params = useParams()
    const customer_id = params?.id || ''

    const [ data, setData ] = useState( {} )
    const [ errors, setErrors ] = useState( {} )

    const [ validated, setValidated ] = useState( false )

    const navigate = useNavigate()

    const fetchCustomer = async ( customer_id ) => {
        const customer = await server.useApi( {
            url: `api/v1/customers/${ customer_id }`, method: "get"
        } )

        setData( customer.data )
    }

    useEffect( () => {
        if ( customer_id ) {
            fetchCustomer( customer_id )
        }
    }, [ customer_id ] )

    const handleSubmit = async ( event ) => {
        event.preventDefault()
        event.stopPropagation()

        const url = params.id ? `api/v1/customers/${ customer_id }/` : `api/v1/customers/`

        server.useApi( {
            url: url, method: params.id ? "patch" : 'post', data: data
        } )
            .then( ( data ) => {
                let msg = ''

                if ( customer_id ) {
                    msg = 'Customer has been successfully update'
                    fetchCustomer( customer_id )
                } else {
                    msg = 'Customer has been successfully created'
                    navigate( '/customers' )
                }

                toast.success( msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                } )
            } )
            .catch( ( error ) => {
                const response = error?.response?.data
                let errors_obj = {}

                Object.entries( response ).forEach( ( v, i ) => {
                    errors_obj[ v[ 0 ] ] = v[ 1 ][ 0 ]
                } )

                setErrors( errors_obj )
            } )


        setValidated( true )

    }
    return (
        <>
            <Form noValidate validated={ validated } onSubmit={ handleSubmit }>
                <Card className="mb-4" >
                    <Card.Header>
                        User Info
                    </Card.Header>

                    <Card.Body>
                        <Row className="mb-3">
                            <Form.Group as={ Col } md="4">
                                <Form.Label htmlFor="id_external_id">External Id</Form.Label>
                                <Form.Control
                                    name="external_id"
                                    type="text"
                                    id="id_external_id"
                                    defaultValue={ data.external_id }
                                    onChange={ e => data.external_id = e.target.value }
                                    isInvalid={ !!errors.external_id }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.external_id }
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={ Col } md="4">
                                <Form.Label htmlFor="id_text">Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="id_name"
                                    defaultValue={ data.name }
                                    onChange={ e => data.name = e.target.value }
                                    isInvalid={ !!errors.name }
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.name }
                                </Form.Control.Feedback>
                            </Form.Group>
                            { data.hasOwnProperty( 'territory' ) || !customer_id ?

                                <Form.Group as={ Col } md="4">
                                    <Form.Label htmlFor="id_territory">Territory</Form.Label>
                                    <Select
                                        name="territory"
                                        defaultValue={ TERRITORIES.find( territory => territory.value === parseInt( data.territory ) ) }
                                        id="id_territory"
                                        options={ TERRITORIES }
                                        onChange={ e => data.territory = e.value }
                                    />
                                </Form.Group > : '' }
                        </Row>

                        <Row className="mb-3">

                            { data.hasOwnProperty( 'route' ) || !customer_id ?

                                <Form.Group as={ Col } md="4">
                                    <Form.Label htmlFor="id_route">Route</Form.Label>
                                    <Select
                                        name="route"
                                        defaultValue={ ROUTES.find( route => parseInt( route.value ) === parseInt( data.route ) ) }
                                        id="id_route"
                                        options={ ROUTES }
                                        onChange={ e => data.route = e.value }
                                    />
                                </Form.Group >
                                : '' }

                            { data.hasOwnProperty( 'zone' ) || !customer_id ?
                                <Form.Group as={ Col } md="4">
                                    <Form.Label htmlFor="zone">Zone</Form.Label>
                                    <Select
                                        name="zone"
                                        defaultValue={ ZONES.find( zone => zone.value === parseInt( data.zone ) ) }
                                        id="zone"
                                        options={ ZONES }
                                        onChange={ e => data.zone = e.value }
                                    />
                                </Form.Group >
                                : '' }
                            { data.hasOwnProperty( 'status' ) || !customer_id ?
                                <Form.Group as={ Col } md="4">
                                    <Form.Label htmlFor="status">Status</Form.Label>
                                    <Select
                                        name="status"
                                        defaultValue={ data.status && Object.values(data.status).map( st => CUSTOMER_STATUS.find( status => status.value === parseInt( st ) ))  }
                                        id="status"
                                        options={ CUSTOMER_STATUS }
                                        onChange={ options => data.status = options.map( option => option.value )}
                                        isMulti
                                    />
                                </Form.Group >
                                : '' }
                        </Row >
                    </Card.Body >
                </Card >

                <Button type="submit" variant="primary">{ customer_id ? 'Update' : 'Create' }</Button>
                <Button type="button" variant="" onClick={ e => navigate( '/customers' ) }>Cancel</Button>
            </Form>
        </>
    )
}
export default CustomerForm