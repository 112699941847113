import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Card, Badge } from "react-bootstrap"
import DataTable from "react-data-table-component"
import { FaEdit } from 'react-icons/fa'

import server from '../../server'
import { PRODUCT_STATUS, SOURCES } from '../../services/constant'
import AddProduct from './AddProduct'
import useAuth from '../../services/useAuth'
import checkIfAdmin from '../../services/checkIfAdmin'

const Products = ( { customer_id = undefined, imported } ) => {
    const [ data, setData ] = useState( [] )
    const [ loading, setLoading ] = useState( false )
    const [ totalRows, setTotalRows ] = useState( 0 )
    const [ size, setSize ] = useState( 50 )
    const [ page, setPage ] = useState( 1 )

    const user = useAuth()

    const fetchProducts = async ( page, size, customer_id ) => {
        setLoading( true )

        const products = await server.useApi( {
            url: `api/v1/customers/${ customer_id }/products/`, method: "get", params: {
                page: page,
                size: size,
            }
        } ).catch( ( error ) => {

        } )

        setData( products?.data?.results )
        setTotalRows( products?.data?.count )
        setLoading( false )
    }

    const getProductStatuses = ( row ) => (
        <div className="mt-2 mb-2">
            {
                row.status?.map( st => {
                    const stat = PRODUCT_STATUS.find( status => status.value === parseInt( st ) ) || st

                    return <Badge bg={ stat.type } className="ms-1 mb-1">
                        { stat.label }
                    </Badge>
                } )
            }
        </div>
    )

    const columns = [
        {
            name: 'Item Code',
            selector: row => row.product_item_code,
            sortable: true,
            sortField: 'item_code'
        },
        {
            name: 'Name',
            selector: row => row.product_name,
            sortable: true,
            wrap: true,
            sortField: 'name'
        },
        {
            name: 'Source',
            selector: row => SOURCES.find( source => source.value === parseInt( row.product_source ) )?.label || '-',
            sortable: true,
            wrap: true,
            sortField: 'source'
        },
        {
            name: 'Status',
            selector: row => getProductStatuses( row ),
            sortable: true,
            wrap: true,
            width: "200px",
            sortField: 'status'
        },
        {
            name: 'Replacement Product',
            selector: row => row.replacement_name,
            sortable: true,
            wrap: true,
            width: "200px",
            sortField: 'replacement'
        },
        {
            cell: ( row ) => <>
                { checkIfAdmin( user ) && <Link to={ `/customer/${ customer_id }/product/${ row.id }/edit` } className='me-2' ><FaEdit /></Link> }
            </>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    const handlePageChange = page => {
        setPage( page )
    }

    const handlePerRowsChange = async ( size ) => {
        setSize( size )
    }

    const conditionalRowStyles = [
        {
            when: row => "7" === row.status,
            classNames: [ 'terminated' ]
        },
    ]


    useEffect( () => {
        fetchProducts( page, size, customer_id )
    }, [ page, size, customer_id ] )

    return (
        <Card >
            <Card.Body>
                <AddProduct customerId={ customer_id } updated={ () => fetchProducts( page, size, customer_id ) } />
                <DataTable
                    title="Products"
                    columns={ columns }
                    data={ data }
                    progressPending={ loading }
                    pagination
                    paginationServer
                    paginationTotalRows={ totalRows }
                    paginationDefaultPage={ page }
                    onChangeRowsPerPage={ handlePerRowsChange }
                    onChangePage={ handlePageChange }
                    paginationRowsPerPageOptions={ [ 50, 100, 500 ] }
                    paginationPerPage={ size }
                    dense
                    conditionalRowStyles={ conditionalRowStyles }
                />
            </Card.Body>
        </Card>
    )
}

export default Products
