import { Link } from 'react-router-dom'
import { FaUsers, FaHistory, FaSignOutAlt, FaClock } from 'react-icons/fa'
import { CiViewList } from "react-icons/ci"
import { useLocation } from "react-router-dom"

import "./index.scss"
import { resetRetry } from '../../Api'
import { deleteAuthTokenFromStore, deleteRefTokenFromStore } from '../../services/localStorageService'

const SidebarMenu = () => {
    const location = useLocation()

    const handleSignOut = () => {
        resetRetry()

        deleteAuthTokenFromStore()
        deleteRefTokenFromStore()

        window.location.reload()
    }

    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" >
            <div className="position-sticky pt-3" >
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link className={ `nav-link ${ location.pathname === '/customers' ? 'active' : '' }` } to="/customers">
                            <FaUsers className="feather feather-home" />
                            Customers
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={ `nav-link ${ location.pathname === '/products' ? 'active' : '' }` } to="/products">
                            <CiViewList className="feather feather-home" />
                            Products
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={ `nav-link ${ location.pathname === '/sales-history' ? 'active' : '' }` } to="/sales-history">
                            <FaHistory className="feather feather-home" />
                            Sales History
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={ `nav-link ${ location.pathname === '/periods' ? 'active' : '' }` } to="/periods">
                            <FaClock className="feather feather-home" />
                            Periods
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className='nav-link' to='#' onClick={ handleSignOut }>
                            <FaSignOutAlt className="feather feather-home" />
                            SignOut
                        </Link>
                    </li>
                </ul>
            </div >
        </nav >
    )
}

export default SidebarMenu