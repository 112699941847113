const TERRITORIES = [
    {
        value: 1, label: "SRQ"
    },
    {
        value: 2, label: "FTM"
    },
    {
        value: 3, label: "TPA"
    },
    {
        value: 4, label: "ORD"
    },
]

const ZONES = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
]

const CUSTOMER_STATUS = [
    { value: 1, label: 'ACTIVE' },
    { value: 2, label: 'ON DEMAND' },
    { value: 3, label: 'PROSPECT' },
    { value: 4, label: 'INACTIVE' },
]

const ROUTES = [
    { value: 1, label: "A" },
    { value: 2, label: "B" },
    { value: 3, label: "C" },
    { value: 4, label: "D" },
    { value: 5, label: "CA" },
    { value: 6, label: "BD" },
    { value: 7, label: "AC" },
    { value: 8, label: "DB" },
]

const PRODUCT_STATUS = [
    { value: 1, label: "DISCONTINUED", type: "warning" },
    { value: 2, label: "NON-FEATURED", type: "warning" },
    { value: 3, label: "ACTIVE", type: "success" },
    { value: 4, label: "PROSPECT", type: "default" },
    { value: 5, label: "INACTIVE", type: "danger" },
    { value: 6, label: "REPLACED", type: "danger" },
    { value: 7, label: "TERMINATED", type: "danger" },
]

const SOURCES = [
    { value: 1, label: "VISTA" },
    { value: 2, label: "NON-VISTA" }
]

const PERIODS = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
]

const CUSTOMER_PRODUCT_STATUS = [
    { value: 6, label: 'Replaced' }
]

export { TERRITORIES, ZONES, CUSTOMER_STATUS, ROUTES, PRODUCT_STATUS, SOURCES, PERIODS, CUSTOMER_PRODUCT_STATUS }