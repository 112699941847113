import { useRef } from 'react'
import { Button } from 'react-bootstrap'
import { TfiImport } from 'react-icons/tfi'
import server from '../../server'
import { toast } from 'react-toastify'

const ImportCustomers = ( { onSuccess } ) => {
    const fileInput = useRef( null )

    const triggerInputFile = () => fileInput.current.click()

    const handleFileChange = ( e ) => {
        const formData = new FormData()

        formData.append( 'file', e.target.files[ 0 ] )

        server.useApi( {
            url: "/api/v1/customers/import/",
            method: "post",
            data: formData,
        } )
            .then( ( response ) => {
                toast.success( response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                } )

                onSuccess( 1 )

            } ).catch( ( error ) => {
                const message = error?.response?.data?.message

                if ( message ) {
                    toast.error( message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    } )
                }
            } )

        e.target.value = null
    }

    return (
        <>
            <div className='float-end mb-3'>
                <input style={ { display: 'none' } } ref={ fileInput } type='file' onChange={ ( e ) => handleFileChange( e ) }
                    accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' />
                <Button className='d-flex align-items-center' onClick={ triggerInputFile } ><TfiImport className='me-2' />Import </Button>
            </div>
        </>
    )
}

export default ImportCustomers