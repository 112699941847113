import { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"

import { Button, Form, Col, Row, Card } from "react-bootstrap"
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'

import server from '../../server'

const SalesHistoryEdit = () => {
  const params = useParams()
  const salesHistoryId = params.id
  const [ errors, setErrors ] = useState( {} )
  const [ validated, setValidated ] = useState( false )

  const [ salesHistory, setSalesHistory ] = useState( {} )
  const [ defaultProduct, setDefaultProduct ] = useState( null )

  const navigate = useNavigate()

  const fetchSalesHistory = async ( salesHistoryId ) => {
    const sales_history = await server.useApi( {
      url: `api/v1/customers/sales-history/${ salesHistoryId }/`, method: "get"
    } )

    if ( sales_history ) {
      setSalesHistory( sales_history.data )
    }

    if ( sales_history?.data?.product ) {
      setDefaultProduct( { label: sales_history?.data?.product_name, value: sales_history?.data?.product } )
    }
  }

  const getProducts = async ( input, callback ) => {
    const products = await server.useApi( {
      url: `api/v1/products/`,
      method: "get",
      params: {
        name: input,
      }
    } )

    const options = []

    if ( products?.data.results ) {
      products.data.results.forEach( ( product ) => {
        options.push( {
          label: product.name,
          value: product.id
        } )
      } )

      callback( options )
    }

  }

  const handleSubmit = async ( event ) => {
    event.preventDefault()
    event.stopPropagation()

    const response = await server.useApi( {
      url: `api/v1/customers/sales-history/${ salesHistoryId }/`,
      method: "patch", data: salesHistory
    } )

    if ( 'OK' === response.statusText ) {

      let msg = ''

      msg = 'Sales history has been successfully update'
      navigate( `/customers/${ salesHistory.customer_id }` )
      toast.success( msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      } )

      setValidated( true )
    } else {
      let errors_obj = {}

      Object.entries( response.data ).forEach( ( v, i ) => {
        errors_obj[ v[ 0 ] ] = v[ 1 ][ 0 ]
      } )

      setErrors( errors_obj )
      setValidated( false )
    }
  }

  useEffect( () => {
    fetchSalesHistory( salesHistoryId )
  }, [ salesHistoryId ] )


  return (
    <>
      <Form noValidate validated={ validated } onSubmit={ handleSubmit }>
        <Card className="mb-4" >
          <Card.Header>
            Update Sales History ( { salesHistory.customer } )
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Form.Group as={ Col } md="4">
                <Form.Label htmlFor="id_date">Date</Form.Label>
                <Form.Control
                  name="date"
                  type="date"
                  id="id_date"
                  disabled
                  defaultValue={ salesHistory.date }
                  isInvalid={ !!errors.date }
                />
                <Form.Control.Feedback type="invalid">
                  { errors.date }
                </Form.Control.Feedback>
              </Form.Group >
              <Form.Group as={ Col } md="4">
                <Form.Label htmlFor="id_product">Product</Form.Label>
                { salesHistory.product ?
                  <AsyncSelect
                    name="product"
                    value={ defaultProduct }
                    id="id_product"
                    loadOptions={ getProducts }
                    onChange={ option => {
                      salesHistory.product = option.value
                      setDefaultProduct( { label: option.label, value: option.value } )
                    }
                    }
                    required
                  /> : null }
                <Form.Control.Feedback type="invalid">
                  { errors.product }
                </Form.Control.Feedback>
              </Form.Group >
              <Form.Group as={ Col } md="2">
                <Form.Label htmlFor="id_quantity">Quantity</Form.Label>
                <Form.Control
                  name="quantity"
                  type="number"
                  id="id_quantity"
                  defaultValue={ salesHistory.quantity }
                  onChange={ e => salesHistory.quantity = e.target.value }
                  isInvalid={ !!errors.quantity }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  { errors.quantity }
                </Form.Control.Feedback>
              </Form.Group >
              <Form.Group as={ Col } md="2">
                <Form.Label htmlFor="status">Unit Price</Form.Label>
                <Form.Control
                  name="unit_price"
                  type="number"
                  step={ 0.1 }
                  id="id_unit_price"
                  defaultValue={ salesHistory.unit_price }
                  onChange={ e => salesHistory.unit_price = e.target.value }
                  isInvalid={ !!errors.unit_price }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  { errors.unit_price }
                </Form.Control.Feedback>
              </Form.Group >
            </Row >
          </Card.Body >
        </Card >

        <Button type="submit" variant="primary">Update</Button>
        <Button type="cancel" className='ms-3' onClick={ () => navigate( `/customers/${ salesHistory.customer_id }` ) } variant="secondary">Cancel</Button>
      </Form>
    </>
  )
}

export default SalesHistoryEdit