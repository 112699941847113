import { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'

import { useForm, Controller } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import server from '../../server'
import { toast } from 'react-toastify'

const AddProduct = ( { customerId , updated} ) => {
  const [ show, setShow ] = useState( false )

  const handleClose = () => setShow( false )
  const handleShow = () => setShow( true )

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async ( data ) => {
    const product = await server.useApi( {
      url: `api/v1/customers/${ customerId }/products/attach/`,
      method: "post",
      data: {
        product_id: data?.product.value,
      }
    } )

    if ( product ) {
      toast.success( "Product has been successfully attached", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      } )

      handleClose()
    } else {
      toast.error( "Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      } )
    }

    reset()
    updated()
  }

  const getProducts = async ( input, callback ) => {
    const products = await server.useApi( {
      url: `api/v1/products/`,
      method: "get",
      params: {
        name: input,
        exclude_customer_id: customerId
      }
    } )

    const options = []

    if ( products?.data.results ) {
      products.data.results.forEach( ( item ) => {
        options.push( {
          label: item.name,
          value: item.id
        } )
      } )

      callback( options )
    }

  }

  return (
    <>
      <Button variant="primary" onClick={ handleShow } className='float-end'>
        Attach Product
      </Button>

      <Modal show={ show } onHide={ handleClose }>
        <Modal.Body>
          <Form onSubmit={ handleSubmit( onSubmit ) }  >
            <Form.Group className="mb-3">
              <Form.Label>Product</Form.Label>
              <Controller
                control={ control }
                { ...register( 'product', {
                  required: "The field is required"
                } ) }
                render={ ( { field } ) => (
                  <AsyncSelect
                    { ...field }
                    isClearable
                    placeholder={ "Select Product" }
                    loadOptions={ getProducts }
                  />
                ) }
              />

              { errors.product && "required" === errors.product.type && (
                <p className='text-danger mt-1'> { errors.product.message }</p>
              ) }
            </Form.Group>

            <Button variant="secondary" onClick={ handleClose } className='me-3'>
              Close
            </Button>
            <Button type='submit' variant="primary">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddProduct