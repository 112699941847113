import DataTable from "react-data-table-component"
import { Card, Row, Col, Form } from "react-bootstrap"
import { useState } from 'react'
import { useEffect } from 'react'
import server from '../../server'
import moment from "moment"
import Select from 'react-select'
import services from '../../services'
import { CUSTOMER_PRODUCT_STATUS, PERIODS } from '../../services/constant'
import AsyncSelect from 'react-select/async'

const Projections = ( { customer_id, imported } ) => {
    const defaultPeriod = services.period( moment() )
    const currentYear = moment().year()

    let initialFilters = {
        year: [ currentYear ],
        period: [ defaultPeriod.value ],
        products: []
    }

    const [ results, setResults ] = useState( [] )
    const [ loading, setLoading ] = useState( false )
    const [ filters, setFilters ] = useState( initialFilters )

    let minYear = 2017

    let years = []

    while ( true ) {
        years.push( { value: minYear, label: minYear } )
        minYear += 1

        if ( currentYear + 3 < minYear ) {
            break
        }
    }

    const columns = [
        {
            name: 'Product',
            selector: row => row.product,
            sortable: true,
            wrap: true,
            sortField: 'product'
        },
        {
            name: 'Projection',
            selector: row => row.total,
            sortable: true,
            wrap: true,
            sortField: 'date'
        },
    ]

    const fetchCustomerSalesPrediction = async ( customer_id, filters ) => {
        setLoading( true )

        const products = await server.useApi( {
            url: `api/v1/customers/${ customer_id }/sales/predictions`, method: "get", params: {
                ...filters
            }
        } ).catch( ( error ) => {

        } )

        if ( products?.data ) {
            setResults( products.data )
        }

        setLoading( false )
    }

    const getOptions = ( input, callback ) => {
        server.useApi( {
            url: `api/v1/customers/${ customer_id }/products/`,
            method: "get", params: {
                name: input,
            }
        } ).then( ( response ) => {
            const options = []

            if ( response?.data.results ) {
                response.data.results.forEach( ( item ) => {
                    options.push( {
                        label: item.product_name,
                        value: item.product
                    } )
                } )

                callback( options )
            }
        } )
    }

    useEffect( () => {
        fetchCustomerSalesPrediction( customer_id, filters )
    }, [ customer_id, filters, imported ] )

    return (
        <>
            <Card className='mb-3'>
                <Card.Header>
                    Filters
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md="4">
                            <Form.Label htmlFor="id_product">Year</Form.Label>

                            <Select
                                defaultValue={ { label: currentYear, value: currentYear } }
                                options={ years }
                                onChange={ options => setFilters( { ...filters, year: options.map( option => option.value ) } ) }
                                isMulti
                            />
                        </Col>
                        <Col md="4">
                            <Form.Label htmlFor="id_product">Period</Form.Label>

                            <Select
                                defaultValue={ { label: defaultPeriod.value, value: defaultPeriod.value } }
                                options={ PERIODS }
                                onChange={ options => setFilters( { ...filters, period: options.map( option => option.value ) } ) }
                                isMulti
                            />
                        </Col>
                        <Col md="4">
                            <Form.Label htmlFor="id_product">Product Name</Form.Label>
                            <AsyncSelect
                                cacheOptions
                                // defaultValue={ () => { getOptions.map( option => filters.products.indexOf( option.value ) ) } }
                                loadOptions={ getOptions }
                                defaultOptions={ true }
                                onChange={ options => setFilters( { ...filters, products: options.map( option => option.value ) } ) }
                                isMulti
                            />
                        </Col>
                        <Col md="4" className='mt-2'>
                            <Form.Label htmlFor="id_status">Product Status</Form.Label>
                            <Select
                                id="id_status"
                                onChange={ options => setFilters( { ...filters, status: options.map( option => option.value.toString() ) } ) }
                                options={ CUSTOMER_PRODUCT_STATUS }
                                isMulti
                            />
                        </Col>
                    </Row >
                </Card.Body>
            </Card>

            <Card >
                <Card.Body>
                    {
                        Object.keys( results ).map( key => (
                            <>
                                <DataTable
                                    title={ `Projection for ${ key }` }
                                    columns={ columns }
                                    data={ results[ key ] }
                                    progressPending={ loading }
                                    key={ key }
                                />

                                <hr />
                            </>
                        ) )
                    }

                </Card.Body>
            </Card>
        </>
    )
}

export default Projections