import api, { resetRetry } from '../Api'
import { getAuthTokenFromStore } from '../services/localStorageService'

export default async function useApi ( { url, method, params = {}, data = {} } ) {

    resetRetry()

    const token = getAuthTokenFromStore()

    return api( {
        url: url,
        method: method,
        params: params,
        data,
        headers: { 'Authorization': token ? 'Bearer ' + token : '' },
    } )

}