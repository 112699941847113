import { useEffect, useState } from 'react'
import server from '../server'

const useAuth = () => {
  const [ user, setUser ] = useState( undefined )

  useEffect( () => {
    const fetchData = async () => {
      const { data } = await server.useApi( { url: '/api/v1/account/user/', method: 'get' } )
      setUser( data )
    }

    fetchData()
  }, [] )


  return user
}

export default useAuth