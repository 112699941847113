import login from './login'
import useApi from './useApi'
import refresh from './refresh'
import patch from './patch'

const server = {
    login,
    refresh,
    useApi,
    patch,
}

export default server