import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import server from '../../server'
import { confirmAlert } from 'react-confirm-alert'

const BulkDelete = ( { filters, sales, customerId, updated } ) => {
  const [ disabled, setDisabled ] = useState( true )

  const deleteSalesHistory = ( sales_history_id ) => {
    confirmAlert( {
      title: "Are you sure?",
      message: "You won't be able to revert it!",
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            server.useApi( {
              url: `/api/v1/customers/${ customerId }/sales/bulk-delete/`,
              method: "post",
              data: {
                ...filters
              }
            } ).then( () => {
              toast.success( "Sales have been successfully deleted", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              } )

              updated()
            } )

          }
        },
        {
          label: 'No',
          onClick: () => {
            return false
          }
        }
      ]
    } )
  }

  useEffect( () => {
    setDisabled( Object.values( filters ).length <= 0 || Object.entries( sales ).length <= 0 )
  }, [ filters, sales ] )

  return (
    <>
      <Button className='float-end ms-2 btn btn-danger' disabled={ disabled } onClick={ deleteSalesHistory }>Bulk Delete</Button>

    </>
  )
}

export default BulkDelete