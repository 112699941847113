import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import server from '../../server'
import AsyncSelect from 'react-select/async'

const BulkUpdateSales = ( { filters, sales, customerId, updated } ) => {
  const [ disabled, setDisabled ] = useState( true )

  const [ show, setShow ] = useState( false )

  const handleClose = () => setShow( false )
  const handleShow = () => {
    reset()
    setShow( true )
  }

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async ( data ) => {
    const sales = await server.useApi( {
      url: `/api/v1/customers/${ customerId }/sales/bulk-update/`,
      method: "post",
      data: {
        ...filters,
        product: data?.product?.value,
        unit_price: data?.unit_price
      }
    } )

    if ( sales ) {
      toast.success( "Sales have been successfully updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      } )

      handleClose()
    } else {
      toast.error( "Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      } )
    }

    reset()
    updated()
  }

  const getProducts = async ( input, callback ) => {
    const products = await server.useApi( {
      url: `api/v1/products/`,
      method: "get",
      params: {
        name: input,
      }
    } )

    const options = []

    if ( products?.data.results ) {
      products.data.results.forEach( ( item ) => {
        options.push( {
          label: item.name,
          value: item.id
        } )
      } )

      callback( options )
    }

  }

  useEffect( () => {
    setDisabled( Object.values( filters ).length <= 0 || Object.entries( sales ).length <= 0 )
  }, [ filters, sales ] )

  return (
    <>
      <Button className='float-end ms-2' disabled={ disabled } onClick={ handleShow }>Bulk Update</Button>
      <Modal show={ show } onHide={ handleClose }>
        <Modal.Header>
          Bulk Update
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={ handleSubmit( onSubmit ) }  >
            <Form.Group className="mb-3">
              <Form.Label>Product</Form.Label>
              <Controller
                control={ control }
                { ...register( 'product', {
                  // required: "The field is required",
                  validate: ( value, formValue ) => {
                    // use this validation to check if one of the fields is filled
                    return !!value || !!formValue.unit_price || "Either product or unit price is required"
                  },
                } ) }
                render={ ( { field } ) => (
                  <AsyncSelect
                    { ...field }
                    isClearable
                    placeholder={ "Select Product" }
                    loadOptions={ getProducts }
                  />
                ) }
              />

              { errors.product && (
                <p className='text-danger mt-1'> { errors.product.message }</p>
              ) }
            </Form.Group>

            <Form.Group md="4" className='mb-4'>
              <Form.Label htmlFor="id_text">Unit Price</Form.Label>
              <Form.Control
                type="number"
                step="0.1"
                { ...register( 'unit_price', {
                  validate: ( value, formValue ) => {
                    return !!value || !!formValue.product || "Either product or unit price is required"
                  },
                } ) }
              />
              { errors.unit_price && (
                <p className='text-danger mt-1'> { errors.unit_price.message }</p>
              ) }
            </Form.Group>

            <Button variant="secondary" onClick={ handleClose } className='me-3'>
              Close
            </Button>
            <Button type='submit' variant="primary">
              Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BulkUpdateSales