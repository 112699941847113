import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import "./index.scss"
import server from '../../server'
import { getAuthTokenFromStore, setAuthTokenInStore, setRefTokenInStore } from '../../services/localStorageService'

const Login = ( { loggedIn } ) => {

    const navigate = useNavigate()
    const isLoggedIn = !!getAuthTokenFromStore()

    useEffect( () => {
        if ( isLoggedIn ) {
            navigate( '/customers' )
        }
    }, [ navigate, isLoggedIn ] )

    const [ validated, setValidated ] = useState( false )
    const [ username, setUsername ] = useState( "" )
    const [ password, setPassword ] = useState( "" )

    const [ errors, setErrors ] = useState( {
        username: "",
        password: ""
    } )

    const handleSubmit = ( event ) => {
        event.preventDefault()
        event.stopPropagation()

        server.login( { username: username, passwd: password } )
            .then( ( data ) => {
                setAuthTokenInStore( data.data.access )
                setRefTokenInStore( data.data.refresh )
                loggedIn( true )
                navigate( '/customers' )
            } )
            .catch( ( error ) => {
                const response = error?.response

                if ( 401 === response?.status ) {
                    toast.error( "Invalid Crendtials!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    } )
                }

                if ( 400 === response?.status ) {
                    setErrors( {
                        username: response?.data?.username,
                        password: response?.data?.password,
                    } )
                }

                loggedIn( false )
            } )

        setValidated( true )

    }

    return (
        <div className='form-container'>

            <Form noValidate validated={ validated } onSubmit={ handleSubmit }>
                <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
                <Row className="mb-3">
                    <Form.Group as={ Col } md="12">
                        <Form.Label>Username</Form.Label>

                        <Form.Control
                            required
                            type="text"
                            id="username"
                            value={ username }
                            onChange={ ( e ) => setUsername( e.target.value ) }
                        />
                        <Form.Control.Feedback type="invalid">
                            { errors.username }
                        </Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group as={ Col } md="12">
                        <Form.Label>Password</Form.Label>

                        <Form.Control
                            required
                            type="password"
                            id="password"
                            value={ password }
                            onChange={ ( e ) => setPassword( e.target.value ) }
                        />
                        <Form.Control.Feedback type="invalid">
                            { errors.password }
                        </Form.Control.Feedback>

                    </Form.Group>
                </Row>
                <Button variant="primary" type="submit">Sign In</Button>{ ' ' }
            </Form>
        </div>
    )
}

export default Login
