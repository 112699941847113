import "./index.scss"

const NavBar = () => {

    return (
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/">Vista Serv</a>
        </header>

    )
}

export default NavBar