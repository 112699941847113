import React, { useEffect, useState } from 'react'
import server from '../../server'
import DataTable from 'react-data-table-component'
import { Card, Form, Row, Col, Badge } from 'react-bootstrap'
import Select from 'react-select'
import { FaEdit, FaEye, FaTrash } from "react-icons/fa"
import { confirmAlert } from 'react-confirm-alert' // Import
import ImportCustomers from '../../components/Customers/ImportCustomers'

import { CUSTOMER_STATUS, ROUTES, TERRITORIES, ZONES } from '../../services/constant'
import { Link } from 'react-router-dom'
import useAuth from '../../services/useAuth'
import checkIfAdmin from '../../services/checkIfAdmin'

const Customers = () => {

    let initialFilters = {
        name: '',
        territory: [],
        route: [],
        zone: [],
        status: [],
        external_id: ''
    }

    const user = useAuth()

    const [ data, setData ] = useState( [] )
    const [ loading, setLoading ] = useState( false )
    const [ totalRows, setTotalRows ] = useState( 0 )
    const [ page, setPage ] = useState( 1 )
    const [ size, setSize ] = useState( 10 )
    const [ order, setOrder ] = useState( '-id' )
    const [ filters, setFilters ] = useState( initialFilters )

    const fetchCustomers = async ( page, order, size, filters ) => {
        setLoading( true )

        const response = await server.useApi( {
            url: "api/v1/customers/", method: "get", params: {
                page: page,
                size: size,
                ordering: order,
                ...filters
            }
        } )

        setData( response.data.results )
        setTotalRows( response.data.count )
        setLoading( false )
    }

    const handlePageChange = page => {
        setPage( page )
    }

    const handlePerRowsChange = async ( size ) => {
        setSize( size )
    }

    const handleSort = async ( column, sortDirection ) => {
        let col = column?.sortField || 'id'
        const direction = 'asc' === sortDirection ? '-' : ''
        setOrder( `${ direction }${ col }` )
    }

    useEffect( () => {
        fetchCustomers( page, order, size, filters )
    }, [ page, order, size, filters ] )

    const deleteCustomer = ( customer_id ) => {
        confirmAlert( {
            title: "Are you sure?",
            message: "You won't be able to revert it!",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        server.useApi( {
                            url: `api/v1/customers/${ customer_id }/`, method: "delete"
                        } ).then( ( data ) => {
                            fetchCustomers( 1 )
                        } )

                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false
                    }
                }
            ]
        } )
    }

    const columns = [
        {
            cell: ( row ) => <>
                { checkIfAdmin( user ) && <>
                    <Link to={ `/customers/${ row.id }` } className='text text-success me-2'><FaEye /></Link>
                    <Link to={ `/customers/` } className='text text-danger me-2' onClick={ e => { deleteCustomer( row.id ) } }><FaTrash /></Link>
                    <Link to={ `/customer/${ row.id }/update` }><FaEdit /></Link>
                </>
                }
            </>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Id',
            selector: row => row.external_id ? row.external_id : 'TBD',
            sortable: true,
            wrap: true,
            width: "100px",
            sortField: 'external_id'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            wrap: true,
            sortField: 'name'
        },
        {
            name: 'Territory',
            selector: row => TERRITORIES.find( territory => territory.value === parseInt( row.territory ) )?.label || row.territory,
            sortable: true,
            wrap: true,
            width: "100px",
            sortField: 'territory'
        },
        {
            name: 'Route',
            selector: row => ROUTES.find( route => parseInt( route.value ) === parseInt( row.route ) )?.label || row.zone,
            sortable: true,
            wrap: true,
            width: "100px",
            sortField: 'route'
        },
        {
            name: 'Zone',
            selector: row => ZONES.find( zone => zone.value === parseInt( row.zone ) )?.label || row.zone,
            sortable: true,
            wrap: true,
            width: "100px",
            sortField: 'zone'
        },
        {
            name: 'Status',
            cell: row => <div className='d-flex gap-1'>
                { 
                row.status && Object.values( row.status ).map(
                    rs => <Badge className='d-block' bg="success"> { CUSTOMER_STATUS.find( st => st.value === parseInt( rs ) )?.label} </Badge> 
                ) || row.status }
            </div>,
            sortable: true,
            wrap: true,
            width: "150px",
            sortField: 'status',
            html: true
        }
    ]

    return (
        <>
            <Row>
                <Col>
                    <div className='d-flex float-end mb-3'>
                        <div className="me-2" >
                            <ImportCustomers onSuccess={ fetchCustomers } />
                        </div>
                        <div>
                            <Link className='btn btn-primary' to='/customer/add'>Add New Customer</Link>
                        </div>
                    </div>
                </Col>
            </Row>
            <Card className="mb-4" >
                <Card.Header>
                    Filters
                </Card.Header>

                <Card.Body>

                    <Form>
                        <Row className="mb-3">
                            <Col md="4">
                                <Form.Label htmlFor="external_id">Customer ID</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="external_id"
                                    value={ filters.external_id }
                                    onChange={ e => setFilters( { ...filters, external_id: e.target.value } ) }
                                />
                            </Col>
                            <Col md="4">
                                <Form.Label htmlFor="id_text">Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="id_name"
                                    value={ filters.name }
                                    onChange={ e => setFilters( { ...filters, name: e.target.value } ) }
                                />
                            </Col>
                            <Col md="4">
                                <Form.Label htmlFor="id_territory">Territory</Form.Label>
                                <Select
                                    id="id_territory"
                                    defaultValue={ () => { TERRITORIES.map( option => filters.territory.indexOf( option.value ) ) } }
                                    onChange={ options => setFilters( { ...filters, territory: options.map( option => option.value ) } ) }
                                    options={ TERRITORIES }
                                    isMulti
                                />
                            </Col>

                        </Row>
                        <Row className='mb-3'>
                            <Col md="4">
                                <Form.Label htmlFor="id_routes">Route</Form.Label>
                                <Select
                                    id="id_routes"
                                    defaultValue={ () => { ROUTES.map( option => filters.route.indexOf( option.value ) ) } }
                                    options={ ROUTES }
                                    onChange={ options => setFilters( { ...filters, route: options.map( option => option.value ) } ) }
                                    isMulti
                                />
                            </Col>
                            <Col md="4">
                                <Form.Label htmlFor="id_zone">Zone</Form.Label>
                                <Select
                                    id="id_zone"
                                    defaultValue={ () => { ZONES.map( option => filters.zone.indexOf( option.value ) ) } }
                                    options={ ZONES }
                                    onChange={ options => setFilters( { ...filters, zone: options.map( option => option.value ) } ) }
                                    isMulti
                                />
                            </Col>
                            <Col md="4">
                                <Form.Label htmlFor="id_status">Status</Form.Label>
                                <Select
                                    id="id_status"
                                    defaultValue={ () => { CUSTOMER_STATUS.map( option => filters.status.indexOf( option.value ) ) } }
                                    options={ CUSTOMER_STATUS }
                                    onChange={ options => setFilters( { ...filters, status: options.map( option => option.value ) } ) }
                                    isMulti
                                />
                            </Col>
                        </Row>
                    </Form>

                </Card.Body>
            </Card>
            <Card >
                <Card.Body>
                    <DataTable
                        title="Customers"
                        columns={ columns }
                        data={ data }
                        progressPending={ loading }
                        pagination
                        paginationServer
                        paginationTotalRows={ totalRows }
                        paginationDefaultPage={ page }
                        onChangeRowsPerPage={ handlePerRowsChange }
                        onChangePage={ handlePageChange }
                        onSort={ handleSort }
                        sortServer
                    />
                </Card.Body>
            </Card>
        </>
    )
}

export default Customers
