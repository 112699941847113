import { Navigate } from "react-router-dom"
import { getAuthTokenFromStore } from './services/localStorageService'

const Protected = ( { children } ) => {
    const isLoggedIn = !!getAuthTokenFromStore()

    if ( !isLoggedIn ) {
        return <Navigate to="/login" replace />
    }
    return children
}
export default Protected
