import { useParams } from "react-router-dom"
import server from '../../server'
import { useEffect } from 'react'
import { Row, Col } from "react-bootstrap"
import ImportCustomerSales from '../../components/Customers/ImportSalesHistory'
import CustomerSales from '../../components/Customers/CustomerSales'
import { useState } from 'react'
import Projections from '../../components/Customers/Projections'
import InfoCard from '../../components/Customers/Info'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import ProjectionsChart from '../../components/Customers/ProjectionsChart'
import Products from '../../components/Customers/Products'


const Customer = () => {
    const params = useParams()
    const customer_id = params?.id || ''
    const getActiveTab = JSON.parse( localStorage.getItem( "activeTab" ) ) || 'projection'

    const [ imported, setImported ] = useState( false )
    const [ customerInfo, setCustomerInfo ] = useState( false )
    const [ key, setKey ] = useState( getActiveTab )

    useEffect( () => {
        localStorage.setItem( "activeTab", JSON.stringify( key ) )
    }, [ key ] )

    const fetchCustomer = async ( customer_id ) => {
        const customer = await server.useApi( {
            method: "get",
            url: `/api/v1/customers/${ customer_id }/`,
        } )

        setCustomerInfo( customer.data )
    }

    useEffect( () => {
        fetchCustomer( customer_id )
    }, [ customer_id ] )

    return (
        <>
            <Row>
                <Col>
                    <div className='d-flex float-end mb-3'>
                        <div className="me-2" >
                            <ImportCustomerSales onSuccess={ setImported } customer_id={ customer_id } />
                        </div>
                    </div>
                </Col>
            </Row>
            { customerInfo ? <>
                <div className="mb-3">
                    <InfoCard customer={ customerInfo } />
                </div>

                <Tabs
                    defaultActiveKey="projection"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    unmountOnExit={ true }
                    mountOnEnter={ true }
                    transition={ true }
                    activeKey={ key }
                    onSelect={ ( k ) => setKey( k ) }

                >
                    <Tab eventKey="projection" title="Projections">
                        <div className="mb-3">
                            <Projections customer_id={ customer_id } imported={ imported } />
                        </div>
                    </Tab>
                    <Tab eventKey="projection-chart" title="Projection Chart">
                        <div className="mb-3">
                            <ProjectionsChart customer_id={ customer_id } imported={ imported } />
                        </div>
                    </Tab>
                    <Tab eventKey="history" title="Sales history">
                        <div className="mb-3">
                            <CustomerSales customer_id={ customer_id } imported={ imported } />
                        </div>
                    </Tab>
                    <Tab eventKey="customer-product" title="Products">
                        <div className="mb-3">
                            <Products customer_id={ customer_id } imported={ imported } />
                        </div>
                    </Tab>
                </Tabs>
            </> : '' }
        </>
    )
}

export default Customer
