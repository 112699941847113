import { Card, Row, Col, Form, Table } from "react-bootstrap"
import { useState } from 'react'
import { useEffect } from 'react'
import server from '../../server'
import moment from "moment"
import Select from 'react-select'
import { useRef } from 'react'
import services from '../../services'
import { CUSTOMER_PRODUCT_STATUS, PERIODS } from '../../services/constant'
import AsyncSelect from 'react-select/async'

import "./index.scss"
import SortProjectionChart from './SortProjectionChart'
import Replacement from './Replacement'

const ProjectionsChart = ( { customer_id, imported } ) => {
    let defaultPeriod = useRef( services.period( moment() ) )

    const currentYear = moment().year()
    const [ results, setResults ] = useState( {} )
    const [ filters, setFilters ] = useState( {} )
    const [ sort, setSort ] = useState( false )

    let minYear = 2017

    let years = []

    while ( true ) {
        years.push( { value: minYear, label: minYear } )
        minYear += 1

        if ( currentYear + 3 < minYear ) {
            break
        }
    }

    const fetchCustomerSalesPredictionChart = async ( customer_id, filters, sort ) => {
        const salesHistory = await server.useApi( {
            url: `api/v1/customers/${ customer_id }/sales/prediction-chart`, method: "get", params: {
                ...filters,
                sort: sort ? 'product__name' : '-product__name'
            }
        } ).catch( ( error ) => {

        } )

        setResults( salesHistory?.data )
    }

    const getOptions = ( input, callback ) => {
        server.useApi( {
            url: `api/v1/customers/${ customer_id }/products/`,
            method: "get", params: {
                name: input,
                customer_id: customer_id
            }
        } ).then( ( response ) => {
            const options = []

            if ( response?.data.results ) {
                response.data.results.forEach( ( item ) => {
                    options.push( {
                        label: item.product_name,
                        value: item.product
                    } )
                } )

                callback( options )
            }
        } )
    }

    useEffect( () => {
        fetchCustomerSalesPredictionChart( customer_id, filters, sort )
    }, [ customer_id, filters, sort ] )

    return (
        <>
            <Card className='mb-3'>
                <Card.Header>
                    Filters
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md="6">
                            <Form.Label htmlFor="id_product">Product</Form.Label>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={ getOptions }
                                onChange={ options => setFilters( { ...filters, product_ids: options.map( option => option.value ) } ) }
                                defaultOptions={ true }
                                isMulti
                            />
                        </Col>
                        <Col md="3">
                            <Form.Label htmlFor="id_product">Year</Form.Label>

                            <Select
                                options={ years }
                                defaultValue={ { value: currentYear, label: currentYear } }
                                onChange={ options => setFilters( { ...filters, years: options.map( option => option.value ) } ) }
                                isMulti
                            />
                        </Col>
                        <Col md="3">
                            <Form.Label htmlFor="id_product">Period</Form.Label>

                            <Select
                                options={ PERIODS }
                                defaultValue={ defaultPeriod.current }
                                onChange={ options => setFilters( { ...filters, periods: options.map( option => option.value ) } ) }
                                isMulti
                            />
                        </Col>

                        <Col md="4" className='mt-2'>
                            <Form.Label htmlFor="id_status">Product Status</Form.Label>
                            <Select
                                id="id_status"
                                onChange={ options => setFilters( { ...filters, status: options.map( option => option.value.toString() ) } ) }
                                options={ CUSTOMER_PRODUCT_STATUS }
                                isMulti
                            />
                        </Col>
                    </Row >
                </Card.Body>
            </Card>

            <Card >
                <Card.Body>
                    { results ? <SortProjectionChart sort={ setSort } direction={ sort } /> : null }

                    { Object.keys( results ).map( key => (
                        <div key={ key }>
                            <h3>{ key } {
                                results[ key ]?.replacement_for ? <Replacement name={ results[ key ]?.replacement_for } /> : null
                            }
                            </h3>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {
                                            results[ key ]?.years?.map( ( year, i ) => (
                                                <th key={ i }>{ year }</th>
                                            ) )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        results[ key ]?.periods?.map( ( period, index ) => (
                                            <tr key={ index }>
                                                <th>{ period }</th>

                                                {
                                                    results[ key ]?.years?.map( ( year, i ) => {
                                                        const isCurrentPeriod = year in results[ key ].preds && period in results[ key ].preds[ year ]

                                                        const d = isCurrentPeriod ? results[ key ].preds[ year ][ period ] : results[ key ]?.data?.find( p => p.year === year && p.period === period && p.actual )?.quantity.toString() || '-'
                                                        return (
                                                            <td key={ i } className={ isCurrentPeriod ? 'text-projection' : '' }>
                                                                { d }
                                                            </td>
                                                        )
                                                    } )
                                                }
                                            </tr>
                                        ) )
                                    }
                                </tbody>
                            </Table>
                            <hr></hr>
                        </div>
                    ) )
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default ProjectionsChart