import { Row, Col, Card, Button, Badge } from 'react-bootstrap'

import DataTable from 'react-data-table-component'
import { PRODUCT_STATUS, SOURCES } from '../../services/constant'
import { useState } from 'react'
import server from '../../server'
import { useEffect } from 'react'

import { TfiImport } from "react-icons/tfi"
import { useRef } from 'react'
import { toast } from 'react-toastify'

const Products = () => {
    const fileInput = useRef( null )
    const [ data, setData ] = useState( [] )
    const [ loading, setLoading ] = useState( false )
    const [ totalRows, setTotalRows ] = useState( 0 )
    const [ page, setPage ] = useState( 1 )
    const [ size, setSize ] = useState( 10 )
    const [ order, setOrder ] = useState( '-id' )
    const [ filters ] = useState( {} )

    const triggerInputFile = () => fileInput.current.click()

    const handleFileChange = ( e ) => {
        const formData = new FormData()

        formData.append( 'file', e.target.files[ 0 ] )

        server.useApi( {
            url: "/api/v1/products/import/",
            method: "post",
            data: formData,
        } )
            .then( ( response ) => {
                toast.success( response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                } )

                fetchProducts( 1 )

            } ).catch( ( error ) => {
                const message = error?.response?.data?.message

                if ( message ) {
                    toast.error( message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    } )
                }
            } )

        e.target.value = null

    }

    const fetchProducts = async ( page, size, order, filters ) => {
        setLoading( true )

        const products = await server.useApi( {
            url: "api/v1/products/", method: "get", params: {
                page: page,
                size: size,
                ordering: order,
                ...filters
            }
        } ).catch( ( error ) => {

        } )

        setData( products?.data?.results )
        setTotalRows( products?.data?.count )
        setLoading( false )
    }

    const getProductStatuses = ( row ) => (
        <div className="mt-2 mb-2">
            {
                row.status?.map( st =>
                    <Badge bg={ "success" } className="ms-1 mb-1">
                        { PRODUCT_STATUS.find( status => status.value === parseInt( st ) )?.label || st }
                    </Badge>
                )
            }
        </div>
    )

    const columns = [
        {
            name: 'Item Code',
            selector: row => row.item_code,
            sortable: true,
            sortField: 'item_code'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            wrap: true,
            sortField: 'name'
        },
        {
            name: 'Source',
            selector: row => SOURCES.find( source => source.value === parseInt( row.source ) )?.label || row.status,
            sortable: true,
            wrap: true,
            width: "200px",
            sortField: 'source'
        },
        {
            name: 'Status',
            selector: row => getProductStatuses( row ),
            sortable: true,
            wrap: true,
            sortField: 'status'
        },
        {
            name: 'Replacement Product',
            selector: row => row.replacement_product,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: "300px",
        }
    ]

    const handlePageChange = page => {
        setPage( page )
    }

    const handlePerRowsChange = async ( size ) => {
        setSize( size )
    }

    const handleSort = async ( column, sortDirection ) => {
        let col = column?.sortField || 'id'
        const direction = 'asc' === sortDirection ? '-' : ''
        setOrder( `${ direction }${ col }` )
    }

    useEffect( () => {
        fetchProducts( page, size, order )
    }, [ page, size, order, filters ] )

    return (
        <>
            <Row>
                <Col>
                    <div className='float-end mb-3'>
                        <input style={ { display: 'none' } } ref={ fileInput } type='file' onChange={ ( e ) => handleFileChange( e ) }
                            accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' />
                        <Button className='d-flex align-items-center' onClick={ triggerInputFile } ><TfiImport className='me-2' />Import </Button>
                    </div>
                </Col>
            </Row>

            <Card >
                <Card.Body>
                    <DataTable
                        title="Products"
                        columns={ columns }
                        data={ data }
                        progressPending={ loading }
                        pagination
                        paginationServer
                        paginationTotalRows={ totalRows }
                        paginationDefaultPage={ page }
                        onChangeRowsPerPage={ handlePerRowsChange }
                        onChangePage={ handlePageChange }
                        onSort={ handleSort }
                        sortServer
                    />
                </Card.Body>
            </Card>
        </>
    )
}

export default Products