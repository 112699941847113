import { Card } from 'react-bootstrap'

import DataTable from 'react-data-table-component'
import { useState } from 'react'
import server from '../../server'
import { useEffect } from 'react'

const Periods = () => {
    const [ data, setData ] = useState( [] )
    const [ loading, setLoading ] = useState( false )
    const [ totalRows, setTotalRows ] = useState( 0 )
    const [ page, setPage ] = useState( 1 )
    const [ size, setSize ] = useState( 10 )
    const [ order, setOrder ] = useState( '-id' )
    const [ filters ] = useState( {} )

    const fetchPeriods = async ( page, size, order, filters ) => {
        setLoading( true )

        const periods = await server.useApi( {
            url: "api/v1/periods/", method: "get", params: {
                page: page,
                size: size,
                ordering: order,
                ...filters
            }
        } ).catch( ( error ) => {

        } )

        setData( periods?.data?.results )
        setTotalRows( periods?.data?.count )
        setLoading( false )
    }

    const columns = [
        {
            name: 'Year',
            selector: row => row.year,
            sortable: true,
            sortField: 'year'
        },
        {
            name: 'From',
            selector: row => row.from_date,
            sortable: true,
            wrap: true,
            sortField: 'from_date'
        },
        {
            name: 'To',
            selector: row => row.to_date,
            sortable: true,
            wrap: true,
            sortField: 'to_date'
        },
        {
            name: 'Period',
            selector: row => row.period,
            sortable: true,
            wrap: true,
            sortField: 'period'
        }
    ]

    const handlePageChange = page => {
        setPage( page )
    }

    const handlePerRowsChange = async ( size ) => {
        setSize( size )
    }

    const handleSort = async ( column, sortDirection ) => {
        let col = column?.sortField || 'id'
        const direction = 'asc' === sortDirection ? '-' : ''
        setOrder( `${ direction }${ col }` )
    }

    useEffect( () => {
        fetchPeriods( page, size, order )
    }, [ page, size, order, filters ] )

    return (
        <>
            <Card >
                <Card.Body>
                    <DataTable
                        title="Periods"
                        columns={ columns }
                        data={ data }
                        progressPending={ loading }
                        pagination
                        paginationServer
                        paginationTotalRows={ totalRows }
                        paginationDefaultPage={ page }
                        onChangeRowsPerPage={ handlePerRowsChange }
                        onChangePage={ handlePageChange }
                        onSort={ handleSort }
                        sortServer
                    />
                </Card.Body>
            </Card>
        </>
    )
}

export default Periods