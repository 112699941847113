import { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"

import { Button, Form, Col, Row, Card } from "react-bootstrap"
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import { toast } from 'react-toastify'

import server from '../../server'
import { PRODUCT_STATUS } from '../../services/constant'

const CustomerProductEdit = () => {
  const params = useParams()
  const customerProductId = params.id
  const customerId = params.customer

  const [ errors, setErrors ] = useState( {} )
  const [ validated, setValidated ] = useState( false )

  const [ customerProduct, setCustomerProduct ] = useState( null )
  const [ defaultReplacementProduct, setDefaultReplacementProduct ] = useState( null )

  const navigate = useNavigate()

  const fetchCustomerProduct = async ( customerProductId, customerId ) => {
    const response = await server.useApi( {
      url: `api/v1/customers/${ customerId }/products/${ customerProductId }/`, method: "get"
    } )

    if ( response ) {
      setCustomerProduct( response.data )
    }

    if ( response?.data?.replacement ) {
      setDefaultReplacementProduct( { label: response.data.replacement_name, value: response.data.replacement } )
    }
  }

  const getProducts = async ( input, callback ) => {
    const products = await server.useApi( {
      url: `api/v1/products/`,
      method: "get",
      params: {
        name: input,
        exclude_products: [customerProduct.product]
      }
    } )

    const options = []

    if ( products?.data.results ) {
      products.data.results.forEach( ( item ) => {
        options.push( {
          label: item.name,
          value: item.id
        } )
      } )

      callback( options )
    }

  }

  const handleSubmit = async ( event ) => {
    event.preventDefault()
    event.stopPropagation()

    const response = await server.useApi( {
      url: `api/v1/customers/${ customerId }/products/${ customerProductId }/`,
      method: "PUT", data: customerProduct
    } )

    if ( 'OK' === response.statusText ) {

      let msg = ''

      msg = 'Customer Product has been successfully update'
      navigate( `/customers/${ customerId }` )
      toast.success( msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      } )

      setValidated( true )
    } else {
      let errors_obj = {}

      Object.entries( response.data ).forEach( ( v, i ) => {
        errors_obj[ v[ 0 ] ] = v[ 1 ][ 0 ]
      } )

      setErrors( errors_obj )
      setValidated( false )
    }
  }

  useEffect( () => {
    fetchCustomerProduct( customerProductId, customerId )
  }, [ customerProductId, customerId ] )


  return (
    <>
      {
        customerProduct ?
          <Form noValidate validated={ validated } onSubmit={ handleSubmit }>
            <Card className="mb-4" >
              <Card.Header>
                Update Customer Product ( { customerProduct.product_name } )
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Form.Group as={ Col } md="4">
                    <Form.Label htmlFor="id_replacement_product">Replacement Product</Form.Label>
                    <AsyncSelect
                      name="replacement"
                      isClearable
                      defaultValue={ defaultReplacementProduct }
                      id="id_replacement_product"
                      loadOptions={ getProducts }
                      onChange={ option => {
                        customerProduct.replacement = option?.value || null

                        if ( option?.value )
                          setDefaultReplacementProduct( { label: option.label, value: option.value } )
                      }
                      }
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      { errors.replacement }
                    </Form.Control.Feedback>
                  </Form.Group >
                  <Form.Group as={ Col } md="8">
                    <Form.Label htmlFor="id_status">Status</Form.Label>
                    <Select
                      name="status"
                      defaultValue={
                        customerProduct.status?.map( st =>
                          PRODUCT_STATUS.find( status => parseInt( status.value ) === parseInt( st ) )
                        )
                      }
                      id="status"
                      options={ PRODUCT_STATUS }
                      onChange={ options => {
                        customerProduct.status = options.map( option => option.value )
                      } }
                      isMulti
                    />
                    <Form.Control.Feedback type="invalid">
                      { errors.status }
                    </Form.Control.Feedback>
                  </Form.Group >

                </Row >
              </Card.Body >
            </Card >

            <Button type="submit" variant="primary">Update</Button>
            <Button type="cancel" className='ms-3' onClick={ () => navigate( `/customers/${ customerId }` ) } variant="secondary">Cancel</Button>
          </Form> : null
      }

    </>
  )
}

export default CustomerProductEdit