import { Card, Form, Row, Col, Button } from "react-bootstrap"
import DataTable from "react-data-table-component"
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import server from '../../server'
import { useRef, useState } from 'react'
import { useEffect } from 'react'
import { CUSTOMER_PRODUCT_STATUS, PERIODS, ROUTES, TERRITORIES, ZONES } from '../../services/constant'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert' // Import
import BulkUpdateSales from './BulkUpdateSales'
import BulkDeleteSales from './BulkDeleteSales'
import useAuth from '../../services/useAuth'
import checkIfAdmin from '../../services/checkIfAdmin'

const CustomerSales = ( { customer_id = undefined, imported } ) => {
    const user = useAuth()

    let sorts = new Set( [ '-date' ] )
    const currentYear = moment().year()

    const [ data, setData ] = useState( [] )
    const [ loading, setLoading ] = useState( false )
    const [ totalRows, setTotalRows ] = useState( 0 )
    const [ size, setSize ] = useState( 50 )
    const [ page, setPage ] = useState( 1 )
    const [ order, setOrder ] = useState( [ ...sorts ] )
    const [ filters, setFilters ] = useState( {} )
    const [ hideUnitPrice, setHideUnitPrice ] = useState( true )
    const [ bulkUpdated, setBulkUpdated ] = useState( false )
    const end_date = useRef()

    let minYear = 2017

    let years = []

    while ( true ) {
        years.push( { value: minYear, label: minYear } )
        minYear += 1

        if ( currentYear + 3 < minYear ) {
            break
        }
    }

    const deleteSalesHistory = ( sales_history_id ) => {
        confirmAlert( {
            title: "Are you sure?",
            message: "You won't be able to revert it!",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        server.useApi( {
                            url: `api/v1/customers/sales-history/${ sales_history_id }/`, method: "delete"
                        } ).then( ( data ) => {
                            fetchCustomerSales( page, size, order, customer_id, filters )
                        } )

                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false
                    }
                }
            ]
        } )
    }

    let columns = [
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            wrap: true,
            sortField: 'date',
            width: '150px'
        },
        {
            name: 'Product',
            selector: row => row.product_name,
            sortable: true,
            sortField: 'product__name'
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
            wrap: true,
            sortField: 'quantity',
            width: '100px'
        },
        {
            name: 'Unit Price',
            selector: row => `$${ row.unit_price }`,
            sortable: true,
            wrap: true,
            sortField: 'unit_price',
            omit: hideUnitPrice,
            width: '120px'
        },
        {
            name: 'Year',
            selector: row => row.year,
            sortable: true,
            wrap: true,
            sortField: 'year',
            width: '100px'
        },
        {
            name: 'Period',
            selector: row => row.period,
            sortable: true,
            wrap: true,
            sortField: 'period',
            width: '100px'
        },
        {
            cell: ( row ) => <>
                { checkIfAdmin( user ) &&
                    <><Link to={ `/customer/sales-history/${ row.id }/edit` } className='me-2' ><FaEdit /></Link>
                        <Link to={ `#` } className='text text-danger me-2'
                            onClick={ e => { deleteSalesHistory( row.id ) } }>
                            <FaTrash />
                        </Link>
                    </>
                }

            </>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    if ( 'undefined' === typeof customer_id ) {
        columns = [ {
            name: 'Customer',
            cell: ( row ) => <>
                <Link to={ `/customers/${ row.customer_id }` } className='text text-success me-2'>{ row.customer }</Link>
            </>,
            sortable: true,
            wrap: true,
            sortField: 'customer__name',
            width: "250px"
        }, ...columns,
        ]
    }

    const handlePageChange = page => {
        setPage( page )
    }

    const handlePerRowsChange = async ( size ) => {
        setSize( size )
    }

    const handleSort = async ( column, sortDirection ) => {
        let col = column?.sortField || 'date'
        const direction = 'asc' === sortDirection ? '-' : ''

        const sorting = `${ direction }${ col }`
        sorts = new Set( order )

        sorts.delete( col )
        sorts.delete( '-' + col )

        sorts.add( sorting )

        setOrder( [ ...sorts ] )
    }

    const fetchCustomerSales = async ( page, size, order, customer_id, filters ) => {
        setLoading( true )

        const products = await server.useApi( {
            url: `api/v1/customers/sales-history`,
            method: "get", params: {
                page: page,
                size: size,
                ordering: order,
                ...filters,
                customer_id: customer_id
            }
        } ).catch( ( error ) => {

        } )

        setData( products?.data?.results )
        setTotalRows( products?.data?.count )
        setLoading( false )
        setBulkUpdated( false )
    }

    const getOptions = ( input, callback ) => {
        server.useApi( {
            url: customer_id ? `api/v1/customers/${ customer_id }/products/` : `api/v1/products/`,
            method: "get", params: {
                name: input,
                customer_id: customer_id
            }
        } ).then( ( response ) => {
            const options = []

            if ( response?.data.results ) {
                response.data.results.forEach( ( item ) => {
                    customer_id ? options.push( {
                        label: item.product_name,
                        value: item.product
                    } ) : options.push( {
                        label: item.name,
                        value: item.id
                    } )
                } )

                callback( options )
            }
        } )
    }

    useEffect( () => {
        fetchCustomerSales( page, size, order, customer_id, filters )
    }, [ page, size, order, customer_id, imported, filters, bulkUpdated ] )


    return (
        <>
            <Card className="mb-4" >
                <Card.Header>
                    Filters
                </Card.Header>

                <Card.Body>
                    <Form>
                        <Row className="mb-3">
                            <Col md="4">
                                <Form.Label htmlFor="id_product">Product Name</Form.Label>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={ getOptions }
                                    onChange={ options => setFilters( { ...filters, product_id: options.map( option => option.value ) } ) }
                                    defaultOptions={ true }
                                    isMulti
                                />
                            </Col>
                            <Col md="4">
                                <Form.Label htmlFor="id_status">Product Status</Form.Label>
                                <Select
                                    id="id_status"
                                    onChange={ options => setFilters( { ...filters, status: options.map( option => option.value.toString() ) } ) }
                                    options={ CUSTOMER_PRODUCT_STATUS }
                                    isMulti
                                />
                            </Col>

                            <Col md="4">
                                <Form.Label htmlFor="id_product">Period</Form.Label>
                                <Select
                                    id="id_periods"
                                    onChange={ options => setFilters( { ...filters, period: options.map( option => option.value ) } ) }
                                    options={ PERIODS }
                                    isMulti
                                />
                            </Col>
                            <Col md="4" className='mt-2'>
                                <Form.Label htmlFor="id_years">Year</Form.Label>
                                <Select
                                    id="id_years"
                                    onChange={ options => setFilters( { ...filters, years: options.map( option => option.value ) } ) }
                                    options={ years }
                                    isMulti
                                />
                            </Col>
                            { !customer_id ? (
                                <>
                                    <Col md="6" className='mt-2'>
                                        <Form.Label htmlFor="customer_name">Customer Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="customer_name"
                                            onChange={ e => setFilters( { ...filters, customer_name: e.target.value } ) }
                                        />
                                    </Col>

                                    <Col md="2" className='mt-2'>
                                        <Form.Label htmlFor="id_territory">Territory</Form.Label>
                                        <Select
                                            id="id_territory"
                                            onChange={ options => setFilters( { ...filters, customer_territory: options.map( option => option.value ) } ) }
                                            options={ TERRITORIES }
                                            isMulti
                                        />
                                    </Col>

                                    <Col md="2" className='mt-2'>
                                        <Form.Label htmlFor="id_routes">Route</Form.Label>
                                        <Select
                                            id="id_routes"
                                            onChange={ options => setFilters( { ...filters, customer_route: options.map( option => option.value ) } ) }
                                            options={ ROUTES }
                                            isMulti
                                        />
                                    </Col>
                                    <Col md="2" className='mt-2'>
                                        <Form.Label htmlFor="id_zone">Zone</Form.Label>
                                        <Select
                                            id="id_zone"
                                            onChange={ options => setFilters( { ...filters, customer_zone: options.map( option => option.value ) } ) }
                                            options={ ZONES }
                                            isMulti
                                        />
                                    </Col>
                                </>
                            ) : '' }
                            <Col md="4" className='mt-2'>
                                <Form.Label htmlFor="customer_name">From Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    min='2017-01-01'
                                    onChange={ e => {
                                        setFilters( { ...filters, date_start: e.target.value } )
                                        const d1 = new Date( e.target.value )
                                        const d2 = new Date( end_date.current.value )

                                        if ( d1 > d2 ) {
                                            end_date.current.value = ''
                                        }
                                    } }
                                />
                            </Col>
                            <Col md="4" className='mt-2'>
                                <Form.Label htmlFor="customer_name">To date</Form.Label>
                                <Form.Control
                                    type="date"
                                    ref={ end_date }
                                    min={ filters.date_start || '2017-01-01' }
                                    onChange={ e => setFilters( { ...filters, date_end: e.target.value } ) }
                                />
                            </Col>
                        </Row>
                    </Form>

                </Card.Body>
            </Card >
            <Card >
                <Card.Body>
                    <div className='float-end d-flex'>
                        <Button onClick={ () => setHideUnitPrice( !hideUnitPrice ) } >{ hideUnitPrice ? 'Show' : 'Hide' } Unit Price</Button>

                        {
                            checkIfAdmin( user ) && !!customer_id && (
                                <>
                                    <BulkUpdateSales filters={ filters } sales={ data } customerId={ customer_id } updated={ () => setBulkUpdated( true ) } />
                                    <BulkDeleteSales filters={ filters } sales={ data } customerId={ customer_id } updated={ () => setBulkUpdated( true ) } />
                                </>
                            )
                        }
                    </div>
                    <DataTable
                        title="Sales Histroy"
                        columns={ columns }
                        data={ data }
                        progressPending={ loading }
                        pagination
                        paginationServer
                        paginationTotalRows={ totalRows }
                        paginationDefaultPage={ page }
                        onChangeRowsPerPage={ handlePerRowsChange }
                        onChangePage={ handlePageChange }
                        onSort={ handleSort }
                        paginationRowsPerPageOptions={ [ 50, 100, 500 ] }
                        paginationPerPage={ size }
                        dense
                        sortServer
                    />
                </Card.Body>
            </Card>
        </>
    )
}

export default CustomerSales