import { Card, Row, Col } from "react-bootstrap"
import { CUSTOMER_STATUS, ROUTES, TERRITORIES, ZONES } from '../../services/constant'

const InfoCard = ( { customer } ) => {
    return (
        <>
            <Card >
                <Card.Body>
                    <Row>

                        <Col>
                            <p><strong>Customer Name</strong> : { customer.name }</p>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <p><strong>Customer ID</strong> : { customer.external_id }</p>
                        </Col>
                        <Col>
                            <p><strong>Territory</strong> : { TERRITORIES.find( territory => parseInt( territory.value ) === parseInt( customer.territory ) )?.label || customer.territory }</p>
                        </Col>
                        <Col>
                            <p><strong>Route</strong> : { ROUTES.find( route => parseInt( route.value ) === parseInt( customer.route ) )?.label || customer.route }</p>
                        </Col>
                        <Col>
                            <p><strong>Zone</strong> : { ZONES.find( zone => parseInt( zone.value ) === parseInt( customer.zone ) )?.label || customer.zone }</p>
                        </Col>
                        <Col>
                            <p><strong>Status</strong> : { CUSTOMER_STATUS.find( status => parseInt( status.value ) === parseInt( customer.status ) )?.label || customer.status }</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default InfoCard