import React, { useState } from 'react'
import CustomerSales from '../../components/Customers/CustomerSales'
import { Row, Col } from 'react-bootstrap'
import ImportCustomerSales from '../../components/Customers/ImportSalesHistory'

const SalesHistory = () => {
  const [ imported, setImported ] = useState( false )

  return (
    <>
      <Row>
        <Col>
          <ImportCustomerSales onSuccess={ setImported } />
        </Col>
      </Row>

      <CustomerSales imported={ imported } />
    </>
  )
}

export default SalesHistory
