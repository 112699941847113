import Cookie from "js-cookie"

const STORE_REFRESH_TOKEN = 'refresh-token'
const STORE_AUTH_TOKEN = 'auth-token'

const getRefTokenFromStore = () => {
    return Cookie.get( STORE_REFRESH_TOKEN ) || ''
}

const getAuthTokenFromStore = () => {
    return Cookie.get( STORE_AUTH_TOKEN ) || ''
}

const setRefTokenInStore = ( data ) => {
    Cookie.set( STORE_REFRESH_TOKEN, data )
}

const setAuthTokenInStore = ( data ) => {
    Cookie.set( STORE_AUTH_TOKEN, data )
}

const deleteRefTokenFromStore = () => {
    Cookie.remove( STORE_REFRESH_TOKEN )
}

const deleteAuthTokenFromStore = () => {
    Cookie.remove( STORE_AUTH_TOKEN )
}

export { getAuthTokenFromStore, getRefTokenFromStore, setAuthTokenInStore, setRefTokenInStore, deleteRefTokenFromStore, deleteAuthTokenFromStore }