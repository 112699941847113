import moment from "moment"

const period = ( date ) => {
    const year = date.year()

    const periods = [
        [
            moment( `${ year }-01-01`, 'YYYY-MM-DD' ),
            moment( `${ year }-01-29`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-01-29`, 'YYYY-MM-DD' ),
            moment( `${ year }-02-26`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-02-26`, 'YYYY-MM-DD' ),
            moment( `${ year }-03-26`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-03-26`, 'YYYY-MM-DD' ),
            moment( `${ year }-04-23`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-04-23`, 'YYYY-MM-DD' ),
            moment( `${ year }-05-21`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-05-21`, 'YYYY-MM-DD' ),
            moment( `${ year }-06-18`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-06-18`, 'YYYY-MM-DD' ),
            moment( `${ year }-07-16`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-07-16`, 'YYYY-MM-DD' ),
            moment( `${ year }-08-13`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-08-13`, 'YYYY-MM-DD' ),
            moment( `${ year }-09-10`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-09-10`, 'YYYY-MM-DD' ),
            moment( `${ year }-10-08`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-10-08`, 'YYYY-MM-DD' ),
            moment( `${ year }-11-05`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-11-05`, 'YYYY-MM-DD' ),
            moment( `${ year }-12-03`, 'YYYY-MM-DD' ),
        ],
        [
            moment( `${ year }-12-03`, 'YYYY-MM-DD' ),
            moment( `${ year }-12-31`, 'YYYY-MM-DD' ),
        ],
    ]

    let p = {}

    periods.forEach( ( v, k ) => {
        if ( ( v[ 0 ] < date && date <= v[ 1 ] ) ) {
            p = { value: k + 1, label: k + 1 }
        }
    } )

    return p
}

export default period