import api, { resetRetry } from '../Api'
import { getAuthTokenFromStore } from '../services/localStorageService'

export default async function patch ( { url, data = {} } ) {

    resetRetry()

    const token = getAuthTokenFromStore()

    return api.patch( url, data, {
        headers: { 'Authorization': token ? 'Bearer ' + token : '' },
    } )

}