import axios from 'axios'
import { getRefTokenFromStore, setAuthTokenInStore, setRefTokenInStore } from './services/localStorageService'
import NProgress from "nprogress"

function randomInt ( min, max ) {
    return Math.floor( Math.random() * ( max - min + 1 ) + min )
}

function timeDelay ( k ) {
    const base_interval = 0.5
    const base_multiplier = 1.5
    const retry_interval = ( ( base_interval * ( base_multiplier ** ( k - 1 ) ) ) * 1000 )
    const max = k === 5 ? 500 : retry_interval
    return retry_interval + randomInt( 0, max )
}

function wait ( delay ) {
    return new Promise( ( resolve ) => setTimeout( resolve, delay ) )
}

let _retry_count = 0
let _retry = null

export function resetRetry () {
    _retry_count = 0
}

const instance = axios.create( {
    baseURL: process.env.REACT_APP_API_BASEURL,
    timeout: -1,
    headers: {
        'Content-Type': 'application/json',
    },
} )

instance.interceptors.request.use( ( config ) => {
    NProgress.start()
    return config
}, async ( error ) => {
    NProgress.done()
    return Promise.reject( error )
} )

instance.interceptors.response.use( ( response ) => {
    NProgress.done()
    return response
}, async ( err ) => {
    NProgress.done()

    const origReqConfig = err.config
    const status_code = err?.response?.status || 0

    if ( status_code >= 500 && _retry_count < 4 ) {

        _retry_count++

        return wait( timeDelay( _retry_count ) ).then( () => instance.request( origReqConfig ) )

    }

    if ( status_code === 401 && origReqConfig.headers.hasOwnProperty( 'Authorization' ) ) {

        const rtoken = getRefTokenFromStore()

        if ( rtoken && _retry_count < 4 ) {

            _retry_count++

            delete origReqConfig.headers[ 'Authorization' ]

            _retry = refresh( rtoken )
                .finally( () => _retry = null )
                .catch( error => Promise.reject( error ) )

            return _retry.then( ( token ) => {
                origReqConfig.headers[ 'Authorization' ] = `Bearer ${ token }`
                return instance.request( origReqConfig )
            } )

        }

    }

    return Promise.reject( err )
} )

const refresh = async ( rtoken ) => {

    let _rtoken = ''
    let _token = ''

    const params = { refresh_token: rtoken }
    const headers = { headers: { 'Content-Type': 'application/json' } }

    try {

        const response = await axios.post( `${ process.env.REACT_APP_API_BASEURL }/api/v1/token/refresh/`, params, headers )

        _rtoken = response.data.refresh
        _token = response.data.access

        setRefTokenInStore( _rtoken )
        setAuthTokenInStore( _token )

    } catch ( error ) {

        console.log( error )

    } finally {

        return _token

    }

}

export default instance